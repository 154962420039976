import React from "react";
import type { AppProps } from "next/app";
import "./global.scss";
import { studioTitle } from "@internal/dex/buildTime";
import Head from "next/head";

export default function App({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <title>{studioTitle}</title>
            </Head>
            <Component {...pageProps} />
        </>
    );
}
App.displayName = "NextApp";
